import React from "react"
import { navigate } from "gatsby"
import { Layout, Parallax } from "@components/layout"
import {
  Breadcrumbs,
  Modal,
  AnimatedLink,
  ResizedImage,
} from "@components/shared"
import { Posts, CategoryItem, BlogHero } from "@components/blog"
import { withHtml } from "@components/logic"
import dayjs from "dayjs"
import { ProductModal } from "@components/ecommerce"

import {
  blog,
  blog__wrapper,
  blog__wrapper__headers,
  blog__wrapper__posts,
  blog__wrapper__posts__item,
  blog__wrapper__posts__item__date,
  blog__wrapper__posts__item__tags,
  blog__wrapper__posts__item__tags__tag,
} from "./styles/blog.module.scss"
import ScopeSVG from "./icons/scope.svg"
function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}
const Title = withHtml("h4")
const Content = withHtml("p")
const Tags = ({ tags }) => {
  const { nodes } = tags
  return (
    <div className={blog__wrapper__posts__item__tags}>
      {nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink
            to={`/tags/${slug}/`}
            key={i}
            className={blog__wrapper__posts__item__tags__tag}
          >
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}
const PostItem = ({
  title,
  slug,
  content,
  date,
  featuredImage,
  tags,
  categories,
}) => {
  return (
    <div
      className={blog__wrapper__posts__item}
      style={{
        backgroundImage: `url(${featuredImage.node.sourceUrl})`,
        cursor: "pointer",
      }}
      onClick={() => navigate(`/blog/${slug}`)}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          navigate(`/blog/${slug}`)
        }
      }}
      tabIndex={0}
    >
      <div className={blog__wrapper__posts__item__date}>
        {dayjs(date).format("DD.MM.YYYY")}
      </div>
      <h1>{title}</h1>
      <Content>{truncateString(content, 140)}</Content>
      <Tags tags={tags} />
    </div>
  )
}

const Blog = ({ pageContext }) => {
  const { posts, page } = pageContext
  console.log("posts", posts)
  return (
    <Layout {...page}>
      <div className={blog}>
        <div className={blog__wrapper}>
          <div className={blog__wrapper__headers}>
            <h1>Blog</h1>
          </div>
          <div className={blog__wrapper__posts}>
            {posts.map((el, k) => (
              <PostItem {...el} key={k} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export { PostItem }
export default Blog
